.UpdatesList {
  height: calc(100% - 158px);
}

.Updates {
  padding: 15px;
  background-color: rgba(28, 44, 52, 0.8);
}

.Updates h1 {
  color: #d1b080;
  font-size: 1.5em;
  font-family: 'Noto Sans', sans-serif;
  font-weight: bold;
  margin-bottom: 1em;
}

.Updates li {
  margin-bottom: 2em;
  padding-bottom: 15px;
  border-bottom: 1px solid #d1b080;
  max-height: 23%;
}

.Updates li:last-of-type {
  margin-bottom: 0;
  border-bottom: none;
}

.Updates li p {
  color: #cabaa3;
  line-height: 1.4;
  overflow-y: auto;
  height: 66%;
  padding-right: 15px;
}

.Updates li p::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px #39444b;
	border-radius: 4px;
	background-color: transparent;
}

.Updates li p::-webkit-scrollbar {
	width: 12px;
	background-color: #39444b;
  border-radius: 4px;
}

.Updates li p::-webkit-scrollbar-thumb {
	border-radius: 4px;
	-webkit-box-shadow: inset 0 0 6px transparent;
	background-color: #cabaa3;
}

.Updates li h3 {
  font-weight: bold;
  color: #c9ad7f;
  margin-bottom: 0.75em;
}

.LatestUpdates {
  margin-bottom: 15px;
  display: flex;
  min-height: calc(100vh - 20vh);
  flex-direction: column;
  justify-content: flex-end;
}

.UpdatesRow {
  flex-direction: column;
  padding: 0 15px;
}

.UpdatesRow nav {
  margin-top: auto;
}

@media (min-width: 768px) {
  .Updates {
    margin: 0 15px;
  }

  .LatestUpdates {
    margin-bottom: 0;
    margin-right: 7.5px;
  }

  .UpcomingUpdates {
    margin-left: 7.5px;
  }

  .UpdatesRow {
    flex-direction: row;
    padding: 0;
  }
}

@media (max-width: 768px) {
  .Updates {
    flex: 0;
  }
}
