html, body {
  background: white;
  padding: 0;
  margin: 0;
  font-family: 'Noto Sans', sans-serif;
  height: 100%;
  font-size: 16px;
}

html, body, #root {
  min-height: 100%;
}

body:before {
  content: '';
  background-color: transparent;
  background-image: url('https://truetrimmed.com/images/site/trimmed_background.jpg');
  background-size: cover;
  background-position: center;
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  z-index: 0;
}

body:after {
  content: "";
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #111;
  opacity: 0.2;
  position: fixed;
}

#root {
  padding: 61px 0;
  position: relative;
  z-index: 1;
}

body.open {
  overflow: hidden;
}

p {
  margin-bottom: 0;
}

.AppContainer {
  height: calc(100vh - 122px);
}

.LoadingScreen {
  position: absolute;
  top: 25%;
  left: 0;
  text-align: center;
  right: 0;
  width: 50%;
  margin: 0 auto;
}

.LoadingScreen h3 {
  margin-bottom: 5px;
}

.LoadingScreen svg {
  margin-top: 0.5em;
}

.LoadingScreen svg circle {
  fill: #00BFFF;
}

pre {
 height: 500000px;
}
