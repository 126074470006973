.TopNavigation {
  background: #071b25;
  border-top: 1px solid #39444b;
  z-index: 5;
  min-height: 3.75em;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

.TopNavigation__logo {
  display: flex;
  align-items: center;
  height: 60px;
}

.TopNavigation__list {
  margin-top: 0;
  margin-bottom: 0;
  padding-right: 0;
  border-right: 1px solid #39444b;
  display: none;
  justify-content: flex-end;
}

.TopNavigation.open .TopNavigation__list {
  display: flex;
  position: absolute;
  top: 60px;
  border-top: 1px solid #39444b;
  background-color: #071b25;
  flex-direction: column;
}

.TopNavigation.open .TopNavigation__list li {
  width: 100%;
}

.TopNavigation__item {
  list-style: none;
  min-width: 120px;
  border-left: 1px solid #39444b;
  border-right: 1px solid #020709;
  display: block;
  height: 3.688em;
  padding: 0;
  vertical-align: middle;
  border-bottom: 1px solid #39444b;
}

.TopNavigation__item.privacy {
  margin-right: auto;
  border-right: 1px solid #39444b;
}

.TopNavigation__link {
  text-decoration: none;
  color: #d1b080;
  text-transform: lowercase;
  padding: 3px 10px 0;
  font-weight: bold;
  letter-spacing: .04em;
  font-family: 'Noto Sans', sans-serif;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.TopNavigation__item--circle .TopNavigation__link {
  text-transform: uppercase;
}

.TopNavigation__item--circle img {
  border-radius: 50%;
}

button.TopNavigation__link {
  background-color: transparent;
  width: 100%;
  border: none;
}

.TopNavigation__link:hover {
  color: #d1b080;
  text-decoration: none;
  cursor: pointer;
}

.TopNavigation__link:focus {
  outline: none;
}

.TopNavigation__logo img {
  max-width: 100%;
  vertical-align: middle;
  max-height: 50px;
}

.TopNavigation__itemGroup {
  display: flex;
}

.menu-btn span:nth-child(1) {
  top: 0px;
}

.menu-btn span:nth-child(2),
.menu-btn span:nth-child(3) {
  top: 12px;
}

.menu-btn span:nth-child(4) {
  top: 24px;
}

.TopNavigation.open .menu-btn span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

.TopNavigation.open .menu-btn span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.TopNavigation.open .menu-btn span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.TopNavigation.open .menu-btn span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}

.menu-btn {
  margin-left: auto;
  display: flex;
  align-self: center;
  margin-right: 15px;
  cursor: pointer;
  width: 32px;
  height: 40px;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
}

.menu-btn span {
  display: block;
  position: absolute;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
  width: 2em;
  height: 0.1em;
  margin: 0.4em 0 0 0;
  background: #d1b080;
  z-index: 99;
}

.AlertMessage {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 80%;
  margin: auto;
  background-color: tomato;
  border: 1px solid red;
  color: black;
  text-align: center;
  z-index: 300;
  padding: 15px;
}

.AlertMessage p {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .TopNavigation.open .TopNavigation__list {
    padding: 0;
  }
}

@media (min-width: 768px) {
  .TopNavigation {
    box-shadow: 0 2px 3px black;
  }

  .menu-btn {
    display: none;
  }

  .TopNavigation__list {
    display: flex;
  }

  .TopNavigation.open .TopNavigation__list {
    position: relative;
    top: 0;
    border-top: none;
    flex-direction: row;
  }

  .TopNavigation.open .TopNavigation__list li {
    width: auto;
  }

  .TopNavigation__item {
    border-bottom: none;
  }
}

@media (min-width: 992px) {
  .TopNavigation__list {
    margin-right: 15px;
  }
}
