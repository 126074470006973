.Footer {
  background: #071b25;
  border-top: 1px solid #39444b;
  box-shadow: 0 1px 10px black;
  z-index: 5;
  min-height: 3.75em;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
}

.footer-row {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.Footer p {
  color: #d1b080;
  font-weight: bold;
  letter-spacing: .04em;
  font-family: 'Noto Sans', sans-serif;
  margin-bottom: 0;
}

.Footer p span {
  display: none;
}

.Footer p .mobile-info {
  display: inline-block;
}

.copyright-info {
  height: 3.688em;
  display: flex;
  align-items: center;
  margin-right: auto;
}

.social-info {
  text-align: right;
}

.social-info a:not(:first-of-type) {
  margin-left: 1em;
}

.social-info img {
  max-width: 35px;
  max-height: 35px;
}

@media (min-width: 710px) {
  .Footer p span {
    display: inline-block;
  }

  .Footer p .mobile-info {
    display: none;
  }

  .social-info img {
    max-width: 40px;
    max-height: 40px;
  }
}
