.Pagination {
  margin-top: 2em;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
}

.Pagination li.page-item {
  border-bottom: none;
  margin: 0;
  padding: 1em 0;
}

.Pagination li.page-item.active button.page-link {
  color: #39444b !important;
  background-color: #c9ad7f !important;
  border-color: #d1b080 !important;
}

.Pagination button.page-link {
  padding: 0.75rem 1rem;
  min-width: 3.5rem;
  text-align: center;
  box-shadow: none !important;
  border: 1px solid #d1b080;
  background-color: transparent;
  color: #c9ad7f;
  font-weight: 900;
  font-size: 1rem;
  position: relative;
  display: block;
  margin-left: -1px;
  line-height: 1.25;
}

.Pagination button.page-link:hover {
  background-color: #c9ad7f;
  color: #39444b;
  cursor: pointer;
}

.Pagination button.page-link:focus {
  outline: none;
}
